
import { onMounted, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";

export default defineComponent({
  components: {
    CodeHighlighter,
  },

  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const imgDialog = ref(false);

    onMounted(() => {
      setCurrentPageBreadcrumbs(translate("افزونه ها"), []);
    });

    return {
      translate,
      imgDialog,
    };
  },
});
